import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { Product } from 'models';

import close from '../../../assets/close.png';
import styles from './index.module.scss';
import Ingredients from './Ingredients';
import product from 'src/templates/product';

interface Props {
  show: boolean;
  closeModal: Function;
  productUpc: string;
  data?: Product['nutritional_information'];
}

export const NutritionalInfo = (props: Props) => {
  const data = props.data;
  const upc = props.productUpc;
  return (
    <Modal show={props.show} onHide={props.closeModal} size="lg">
      <Modal.Body>
        <div className="cust-modal-content-wrapper">
          <div className="modal-close pos-rel">
            <img onClick={e => props.closeModal()} src={close} />
          </div>
          <div className="cust-modal-content">
            <div className="hdr text-center">
              <h2 className="mgn-bot-40">Nutritional Info: {upc.includes('LAMB') ? "Lamb" : "Chicken"} Dog Box</h2>
            </div>
            <Ingredients 
              nutritional_information={data?.nutritional_description}
            />
            <div className={styles.hr} />
            <div className="row mgn-top-20">
              <div className={`col-12 col-md-6 ${styles.infoContainer}`}>
                <div>
                  <span>Crude Protein </span>
                  <div className={styles.measurement}>
                    min {data?.crude_protein || 28}%
                  </div>
                </div>
                <div>
                  <span>Crude Fat </span>
                  <div className={styles.measurement}>
                    min {data?.crude_fat || 14}%
                  </div>
                </div>
                <div>
                  <span>Crude Fibre</span>
                  <div className={styles.measurement}>
                    max {data?.crude_fibre || 4}%
                  </div>
                </div>
              </div>
              <div className={`col-12 col-md-6 ${styles.infoContainer}`}>
                <div>
                  <span>Moisture</span>
                  <div className={styles.measurement}>
                    max {data?.moisture || 10}%
                  </div>
                </div>
                <div>
                  <span>Calcium</span>
                  <div className={styles.measurement}>
                    {data?.calcium || 2.5}%
                  </div>
                </div>
                <div>
                  <span>Phosphorus</span>
                  <div className={styles.measurement}>
                    {data?.phosphorus || 1.6}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NutritionalInfo;
